<template>
  <div
    class="layout items-center window-height justify-center"
    style="background-color: #93a1ae"
    v-if="!currentWorkspace"
  >
    <div
      style="
        background-color: white;
        padding: 10px 18px 10px 18px;
        border-radius: 5px;
      "
    >
      <q-circular-progress
        indeterminate
        size="40px"
        :thickness="0.1"
        color="primary"
      />
    </div>
  </div>
  <q-layout
    v-else
    :view="drawerLeft === 'mini' ? 'hHh LpR fFf' : 'lHh LpR fFf'"
  >
    <div class="row items-center q-pr-md workspace-header">
      <div>
        <q-icon
          :name="$icons.mdiDockLeft"
          style="color: #cce4ff"
          size="24px"
          class="cursor-pointer app-not-draga"
          @click.native.stop="toggleSidebar"
        >
          <q-tooltip content-class="app-text"> Show/Hide Sidebar </q-tooltip>
        </q-icon>
        <q-icon
          :name="$icons.matOpenInNew"
          style="color: #cce4ff"
          size="22px"
          class="cursor-pointer q-ml-md app-not-draga"
          @click="openCurrentUrl"
        >
          <q-tooltip>Open in new window</q-tooltip>
        </q-icon>
      </div>
      <div class="row flex-no-wrap flex-1 items-center justify-end full-height">
        <!-- Profile Menu -->
        <q-btn
          style="
            margin-right: 13px;
            color: #99caff;
            border: 1px solid #99caff;
            background-color: #006ee5;
          "
          :icon="$icons.mdiHelp"
          round
          size="sm"
          class="help-button app-not-draga"
          @click="openLearnMore"
        >
          <q-tooltip content-class="app-text-normal">Learn more</q-tooltip>
        </q-btn>
        <span class="cursor-pointer">
          <div class="app-not-draga">
            <q-avatar
              size="28px"
              :color="!userProfilePhoto ? 'grey-4' : ''"
              :text-color="!userProfilePhoto ? 'grey-10' : ''"
              @click="showTooltip = false"
            >
              <img
                :src="userProfilePhoto"
                :alt="nameInitials"
                v-if="userProfilePhoto"
              />
              <!-- <span class="text-avatar-style" v-else>
                  {{ nameInitials }} 
                </span> -->
              <avatar
                :customStyle="{ 'font-size': '14px', 'font-weight': '600' }"
                v-else
                :size="28"
                :username="fullName"
              ></avatar>
              <q-tooltip v-if="showTooltip" anchor="top left" self="bottom end">
                {{ fullName | capitalize }} | {{ currentCompany.companyName }}
              </q-tooltip>
              <q-menu
                v-model="menu"
                @before-hide="showTooltip = true"
                content-class="overflow-auto sidebar-menu"
              >
                <q-list dense>
                  <div class="row q-py-sm q-px-md">
                    <q-item-section avatar>
                      <div class="cursor-pointer">
                        <q-avatar
                          size="50px"
                          style="border-radius: 5px"
                          :color="!userProfilePhoto ? 'grey-4' : ''"
                          :text-color="!userProfilePhoto ? 'grey-10' : ''"
                        >
                          <img
                            :src="userProfilePhoto"
                            :alt="nameInitials"
                            v-if="userProfilePhoto"
                          />
                          <!-- <span class="text-avatar-style" v-else>
                              {{ nameInitials }}
                            </span> -->
                          <avatar
                            v-else
                            :size="50"
                            :customStyle="{
                              'font-size': '24px',
                              'font-weight': '600',
                            }"
                            :rounded="false"
                            :username="fullName"
                          ></avatar>
                        </q-avatar>
                      </div>
                    </q-item-section>
                    <q-item-section>
                      <div
                        class="cursor-pointer an-18"
                        style="font-weight: 700"
                      >
                        {{ fullName }}
                      </div>
                      <div class="row flex items-center">
                        <div
                          class="badge-icon online q-mr-sm"
                          style="
                            height: 15px;
                            width: 15px;
                            background-color: #15d89a;
                            border: 1px solid #15d89a;
                            border-radius: 50%;
                          "
                        ></div>

                        <div class="cursor-pointer">Active</div>
                      </div>
                    </q-item-section>
                  </div>
                  <div v-if="statuses" class="row q-py-sm q-px-md">
                    <q-btn
                      size="md"
                      class="dropdown-set mytask-button"
                      no-caps
                      style="
                        font-weight: 400;
                        border: 1px solid #dbecff;
                        background: #dbecff;
                        color: #007aff;
                        padding: 0px 10px;
                        width: 225px;
                      "
                      click.stop
                    >
                      <div style="display: flex" class="q-mr-sm">
                        <img
                          v-if="selectedStatus"
                          :src="'/static/react-icons/' + selectedStatus.icon"
                        />
                        <img
                          v-else-if="user && user.userStatus"
                          :src="'/static/react-icons/' + user.userStatus.icon"
                        />
                        <img v-else src="/static/react-icons/smile.svg" />
                      </div>
                      <div
                        class="button-content"
                        style="display: flex; align-items: center"
                      >
                        <div
                          class="ellipsis"
                          style="width: 125px; text-align: left"
                          v-if="selectedStatus"
                        >
                          {{ selectedStatus.name }}
                        </div>
                        <div
                          class="ellipsis"
                          style="width: 125px; text-align: left"
                          v-else-if="user && user.userStatus"
                        >
                          {{ user.userStatus.name }}
                        </div>
                        <div
                          class="ellipsis"
                          style="width: 125px; text-align: left"
                          v-else
                        >
                          Update your status
                        </div>
                        <q-icon
                          style="margin-right: 0px"
                          :name="$icons.matKeyboardArrowDown"
                        />
                        <q-icon
                          v-if="selectedStatus || (user && user.userStatus)"
                          clickable
                          @click.stop="chooseStatus(null)"
                          style="
                            margin-left: 4px;
                            background-color: #edede2;
                            border-radius: 50%;
                            padding: 1px;
                            margin-right: 0px;
                          "
                          :name="$icons.matClose"
                        >
                          <q-tooltip>Clear status</q-tooltip>
                        </q-icon>
                      </div>
                      <q-menu content-class="overflow-auto" auto-close>
                        <q-list>
                          <q-item
                            @click="chooseStatus(status)"
                            v-for="status in statuses"
                            :key="status.id"
                            clickable
                          >
                            <q-item-section side top>
                              <img
                                :src="'/static/react-icons/' + status.icon"
                              />
                            </q-item-section>
                            <q-item-section class="q-item-ellipsis">
                              {{ status.name }}
                            </q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                  <q-item
                    clickable
                    v-if="isVisible([1, 2])"
                    @click="
                      changeWorkspaceSetting();
                      menu = false;
                    "
                  >
                    <q-item-section>Silence notifications</q-item-section>
                  </q-item>
                  <q-separator v-if="isVisible([1, 2])" />
                  <q-item
                    clickable
                    @click="
                      handleFooterMenuAction('userSetting');
                      menu = false;
                    "
                  >
                    <q-item-section>Profile</q-item-section>
                  </q-item>
                  <q-item
                    v-if="isVisible([1])"
                    @click="
                      handleFooterMenuAction('timeLog');
                      menu = false;
                    "
                    clickable
                  >
                    <q-item-section>Preferences</q-item-section>
                  </q-item>
                  <q-item
                    style="min-height: 24px"
                    @click="loginModel = !loginModel"
                    clickable
                    v-close-popup
                  >
                    <q-item-section side top style="padding-right: 10px">
                      <q-icon
                        :name="$icons.mdiPlusBoxOutline"
                        style="color: #000000"
                      />
                    </q-item-section>
                    <q-item-section>Add teams</q-item-section>
                  </q-item>
                  <q-separator v-if="isVisible([1, 2])" />
                  <div
                    class="row q-py-sm q-px-md"
                    :key="key"
                    v-for="(company, key) in companies"
                  >
                    <q-item-section avatar>
                      <div @click="switchCompany(key)" class="cursor-pointer">
                        <q-avatar
                          style="border-radius: 5px"
                          size="37px"
                          v-if="company.logo"
                        >
                          <img :src="company.logo" :alt="company.companyName" />
                        </q-avatar>
                        <q-avatar
                          style="border-radius: 5px"
                          size="37px"
                          color="blue-grey-4"
                          text-color="white"
                          class="text-bold"
                          font-size="18px"
                          v-else
                        >
                          {{ company.companyName | first2Char }}
                        </q-avatar>
                      </div>
                    </q-item-section>
                    <q-item-section>
                      <div
                        @click="switchCompany(key)"
                        class="cursor-pointer an-18"
                      >
                        {{
                          company && company.companyName.length > 10
                            ? company.companyName.substring(0, 11) + "..."
                            : company.companyName
                        }}
                        <!-- <q-icon
                                name="fiber_manual_record"
                                v-if="
                                  companyNotification[company.companyId] === true
                                "
                                color="pink"
                              /> -->
                      </div>
                    </q-item-section>
                    <q-item-section class="q-pl-none" side>
                      <div v-if="key === currentCompany.companyUri">
                        <q-btn
                          :icon="$icons.matCheck"
                          dense
                          size="13px"
                          color="teal-14"
                          style="background-color: #edfdf8; font-weight: 700"
                          round
                          flat
                        />
                        <!-- <q-btn
                            v-if="isVisible([1])"
                            @click="handleFooterMenuAction('companysetting')"
                            :icon="$icons.mdiCircleEditOutline"
                            color="blue-grey-11"
                            round
                            flat
                            dense
                            size="md"
                          /> -->
                        <q-btn
                          @click="signOutSpecificCompany(key)"
                          :icon="$icons.mdiExitToApp"
                          color="blue-grey-11"
                          round
                          flat
                          dense
                          size="md"
                        />
                      </div>
                      <div v-else>
                        <q-btn
                          @click="signOutSpecificCompany(key)"
                          :icon="$icons.mdiExitToApp"
                          color="blue-grey-11"
                          round
                          flat
                          dense
                          size="md"
                        />
                      </div>
                    </q-item-section>
                  </div>
                </q-list>
              </q-menu>
            </q-avatar>
            <img
              style="position: absolute; right: 8px; width: 18px; top: 10px"
              :src="'/static/react-icons/' + user.userStatus.icon"
              v-if="user.userStatus"
              :alt="user.userStatus.name"
              :title="user.userStatus.name"
            />
          </div>
        </span>
      </div>
    </div>
    <div style="background-color: #FF3279;width: 100%;height: 40px;display: flex;font-size: 16px;font-weight: 500;color: #fff;justify-content: center;align-items: center;">Starting September, 2025 Heycollab will no longer be available. &nbsp;<a href="https://heycollab.com/" target="_blank" style="color: #fff;">Learn more</a></div>
    <q-header
      bordered
      class="bg-white text-white"
      :style="[
        drawerLeft === 'mini' && {
          left: '58px',
          background: '#F9FAFC !important',
        },
        { 'z-index': '999', height: '44px', top: '80px' },
      ]"
    >
      <messaging-toolbar
        :key="$route.path"
        v-if="$route.name === 'MessagingView'"
        :drawerLeft="drawerLeft"
        :toggleDrawerRight="toggleDrawerRight"
        :drawerRight="drawerRight"
        :messageSearch="messageSearch"
        :openVideoDailog="openCompanyVideoDailog"
        :pinPost="pinPosts"
        :bookmarks="bookmarks"
        :bookmarkFolders="bookmarkFolders"
        :labels="taskLabels"
        :copyVideoCallLink="copyVideoCallLink"
        :inviteVideoCallLink="inviteVideoCallLink"
        @closeNotificaiton="closeNotificaiton"
      />
      <board-toolbar
        :key="$route.path"
        v-else-if="$route.name === 'BoardView'"
        :toggleDrawerRight="toggleDrawerRight"
        :boardFilterObj="boardFilterObj"
        :drawerRight="drawerRight"
        :taskView="taskView"
        :changeView="changeView"
        :openVideoDailog="openCompanyVideoDailog"
        :copyVideoCallLink="copyVideoCallLink"
        :inviteVideoCallLink="inviteVideoCallLink"
        :boardSearchOptions="boardSearchOptions"
        @closeNotificaiton="closeNotificaiton"
      />
      <media-toolbar
        :key="$route.path"
        v-else-if="$route.name === 'MediaView'"
        :toggleDrawerRight="toggleDrawerRight"
        :mediaSearch="mediaSearch"
        :drawerRight="drawerRight"
        :mediaView="mediaView"
        :changeView="changeMediaView"
        :openVideoDailog="openCompanyVideoDailog"
        :copyVideoCallLink="copyVideoCallLink"
        :inviteVideoCallLink="inviteVideoCallLink"
        @closeNotificaiton="closeNotificaiton"
      />
      <my-work-toolbar
        v-else-if="$route.name === 'AdminView' || $route.name === 'MemberView'"
        :myWorkDateFilter="myWorkDateFilter"
        :setMyWorkDateFilter="setMyWorkDateFilter"
        :myworkSearch="myworkSearch"
        :labels="taskLabels"
        @closeNotificaiton="closeNotificaiton"
      />
      <saved-items-toolbar
        v-else-if="$route.name === 'SavedItemsView'"
        @closeNotificaiton="closeNotificaiton"
      />
      <learn-view-toolbar v-else-if="$route.name === 'LearnView'" />
      <check-status-banner
        v-if="isShowImportStatusBanner.flag"
        :isShowImportStatusBanner="isShowImportStatusBanner"
        :openImportMenuDialog="openImportMenuDialog"
        @closeBanner="isShowImportStatusBanner.flag = false"
      />
    </q-header>
    <q-drawer
      :value="true"
      :mini="drawerLeft === 'mini'"
      :width="315"
      side="left"
      behavior="desktop"
      content-class="workspaces-sidebar hide-expansion-hover hide-expansion-arrow column"
      content-style="background-color:#FCFCFD"
      id="workspaces-sidebar"
    >
      <!-- <q-btn dense flat round icon="menu" @click="toggleDrawerLeft()" /> -->
      <navigation-sidebar
        :drawerLeft="drawerLeft"
        :toggleDrawerLeft="toggleDrawerLeft"
        :toggleDrawerRight="toggleDrawerRight"
        :unsubscribeAllChannel="unsubscribeAllChannel"
        :drawerRight="drawerRight"
        :footerMenuDialog="footerMenuDialog"
      />
      <!-- drawer content -->
    </q-drawer>
    <q-drawer
      id="workspaces-notification-sidebar"
      v-if="isVisiblePage"
      :value="drawerRight !== null"
      side="right"
      behavior="desktop"
      bordered
      persistent
      class="hide-expansion-arrow hide-expansion-hover custom-scrollbar"
      :width="drawerRight === 'notifications' ? 366 : 325"
    >
      <template v-if="drawerRight === 'info'">
        <info-sidebar
          :workspaceId="parseInt($route.params.workspace)"
          :toggleDrawerRight="toggleDrawerRight"
        />
      </template>
      <template v-if="drawerRight === 'notifications'">
        <notifications-sidebar
          :toggleDrawerRight="toggleDrawerRight"
          :reactOnPost="reactOnPost"
        />
      </template>
      <template v-if="drawerRight === 'help-center'">
        <help-center-sidebar
          :toggleDrawerRight="toggleDrawerRight"
        />
      </template>
    </q-drawer>
    <q-page-container>
      <router-view
        :key="$route.path"
        :workspaces="workspaces2"
        :boardFilterObj="boardFilterObj"
        :mediaSearch="mediaSearch"
        :messageSearch="messageSearch"
        :drawerRight="drawerRight"
        :taskView="taskView"
        :mediaView="mediaView"
        :myWorkDateFilter="myWorkDateFilter"
        :myworkSearch="myworkSearch"
        :isShowImportStatusBanner="isShowImportStatusBanner"
        :boardSearchOptions="boardSearchOptions"
        :toggleDrawerRight="toggleDrawerRight"
        @closeNotificaiton="closeNotificaiton"
      />
      <video-dialog
        v-if="showVideoModel"
        v-model="showVideoModel"
        :resetVideoDialog="resetVideoDialog"
        :videoMediaData="videoMediaData"
        :displayPlayer="displayPlayer"
      ></video-dialog>
      <footer-menu-dialog
        @signOut="signOutSpecificCompany"
        v-if="footerMenuDialog.show"
        :currentWorkspaceId="currentWorkspaceId"
        :currentWorkspaceObj="currentWorkspace"
        :modalDialogObject="modalDialog"
        :footerMenuDialog="footerMenuDialog"
        @closeDialog="closeFooterMenuDialog"
        @navigateTo="navigateTo"
        :isVisible="isVisible"
      />
      <signin-dialog
        v-model="loginModel"
        v-if="loginModel"
        @success="handleLoginSuccess"
        @companyExisted="handleLoggedInCompany"
        @changePassword="handleChangePassword"
        @forgetCompanyName="forgetCompanyHandler"
        @showSingInHandler="showSingInHandler"
      />
      <q-dialog
        transition-hide="none"
        transition-show="none"
        persistent
        v-model="taskDialog"
        @before-hide="closeTaskDialog"
        @keydown.esc="resetTaskDialog"
      >
        <task-dialog
          v-if="editTaskdata"
          :tasks="editTaskdata"
          :reactions="reactions"
          :labels="taskLabels"
          :workspaceid="taskWorkspace.id"
          :workspace="taskWorkspace"
          :isWorkspaceOwner="isWorkspaceOwner"
          :currentUser="user"
          :authToken="currentCompany.accessToken"
          :workspaceMembers="taskWorkspaceMember"
          :taskLabels="taskLabels"
          :openSubtask="openSubtask"
          :openTaskComment="openTaskComment"
          :currentCompany="currentCompany"
          :attachTaskMediaHandler="attachTaskMedia"
          :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
          :deleteTaskCommentHandler="deleteTaskComment"
          :detachTaskAttachmentHandler="detachTaskAttachment"
          @reactOnPost="reactOnPost"
          @updateMediaCache="updateMediaCache"
          @createSubTask="createSubTask"
          @close="resetTaskDialog"
          @success="taskDialogSuccessHandler"
          @clearSubtasks="clearSubtasks"
          @createComment="createComment"
          @clickonmoreoption="clickonmoreoption"
          @deleteCommentPoint="deleteCommentPoint"
          @closeMediaModel="closeMediaModel"
          @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
          @updateComment="updateComment"
          @sendComment="sendComment"
          @deleteMediaComment="deleteMediaComment"
          @updatepositions="updatepositions"
          @addTaskAttchmentToMedia="addTaskAttchmentToMedia"
          @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"
          :openSubtaskDialog="openSubtaskDialog"
        ></task-dialog>
      </q-dialog>
      <q-dialog
        transition-hide="none"
        transition-show="none"
        persistent
        v-model="subTaskDialog"
        @before-hide="closeSubTaskDialog"
      >
        <sub-task-dialog
          :taskLabels="taskLabels"
          v-if="currentSubTask"
          :tasks="currentTask"
          :subTask="currentSubTask"
          :workspaceid="currentWorkspace.id"
          :workspace="currentWorkspace"
          :workspaceMembers="workspaceMembers"
          :currentCompany="currentCompany"
          :activeCompany="activeCompany"
          :attachTaskMediaHandler="attachTaskMedia"
          :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
          :openSubtask="openSubtask"
          :authToken="authToken"
          :reactions="reactions"
          @createComment="createComment"
          :openTaskComment="openTaskComment"
          :deleteTaskCommentHandler="deleteTaskComment"
          @deleteCommentPoint="deleteCommentPoint"
          @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
          @updateComment="updateComment"
          @sendComment="sendComment"
          @deleteMediaComment="deleteMediaComment"
          @clearSubtasks="clearChildSubtasks"
          :backToTask="backToTask"
          @createSubTask="createSubTask"
          :currentUser="user"
          @reactOnPost="reactOnPost"
          @updateMediaCache="updateMediaCache"
          @clickonmoreoption="clickonmoreoption"
          @updatepositions="updatepositions"
          @closeMediaModel="closeMediaModel"
          @addTaskAttchmentToMedia="addTaskAttchmentToMedia"
          @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"
        ></sub-task-dialog>
      </q-dialog>
      <time-log-dialog
        v-if="timelogs.flag"
        v-model="timelogs.flag"
        :timelogs="timelogs.data"
        :taskObj="timelogs.task"
        :board="currentWorkspace.boards[0]"
        :workspace="currentWorkspace"
        :closeDialog="closeTimeLogDialog"
        :isAddTaskTime="timelogs.is_add_time"
        :fromWorkspace="timelogs.is_from_workspace"
      />
      <welcome-dialog
        v-if="user.onboarding_step == 0"
        @closeDialog="updateOnboardingWalkthrough"
      />
      <!-- <div id="heycollab-meet">
			</div> -->
    </q-page-container>
    <q-footer class="update-version-banner">
      <UpdateNotification />
      <planexpired-dialog
        v-if="videoPlayerData.isDialogShow"
        title="Premium feature"
        :description="videoPlayerData.description"
        :video="videoPlayerData.videoUrl"
        @closeDialog="closePlanExpiredDialog"
      />
    </q-footer>
  </q-layout>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { resetState } from "@/store";

import { pusher } from "@/pusher";
import MessagingToolbar from "@/views/WorkspaceView/MessagingToolbar";
import BoardToolbar from "@/views/WorkspaceView/BoardToolbar";
import MyWorkToolbar from "@/views/WorkspaceView/MyWorkToolbar";
import MediaToolbar from "@/views/WorkspaceView/MediaToolbar";
import NavigationSidebar from "@/views/WorkspaceView/NavigationSidebar";
import UpdateNotification from "@/components/UpdateNotification";
import InfoSidebar from "@/views/WorkspaceView/InfoSidebar";
import NotificationsSidebar from "@/views/WorkspaceView/NotificationsSidebar";
import HelpCenterSidebar from "@/views/WorkspaceView/HelpCenterSidebar";
import SavedItemsToolbar from "@/views/CompanyView/SavedItemsToolbar";
import PlanexpiredDialog from "@/components/Dialogs/PlanexpiredDialog";
import LearnViewToolbar from "@/views/CompanyView/LearnViewToolbar";
import CheckStatusBanner from "@/components/CheckStatusBanner";
import SigninDialog from "@/components/Dialogs/SigninDialog";

import VideoDialog from "@/components/VuePlayer/VideoDialog";
import WelcomeDialog from "@/components/Dialogs/WelcomeDialog";
import FooterMenuDialog from "@/components/Dialogs/FooterMenuDialog";

import UserScope from "@/mixins/UserScope";
import ManageDialogMixin from "@/mixins/SidebarMixins/ManageDialogMixin";
import SignInToAnotherCompanyMixin from "@/mixins/SidebarMixins/SignInToAnotherCompanyMixin";
import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";

import TaskDialog from "@/views/BoardView/TaskDialog";
import SubTaskDialog from "@/views/BoardView/SubTaskDialog";

import TimeLogDialog from "@/components/Dialogs/TimeLogDialog";

import Avatar from "vue-avatar";

let meetWindow;

const boardFilterInitialState = {
  filteredMember: [],
  taskFliter: {
    filter: false,
    label: [],
    bookmarkFlag: false,
  },
  myTaskFilter: false,
  duedate: false,
  searchText: null,
};
const boardSearchOptionsInitialState = {
  includeTaskName: true,
  includeCustomField: true,
};
import {
  Workspaces2Query,
  FoldersQuery,
  UserQuery,
  UserPermissionsQuery,
  NotificationsQuery,
  ReactionsQuery,
  StatusesQuery,
  TaskLabelsQuery,
  RolesQuery,
  RolePermissionsQuery,
  ImportDataQuery,
  PinMessagesQuery,
  BookmarksQuery,
  BookmarkFoldersQuery,
  UpdateUserStatusMutation,
  GenerateJwtTokenForUser,
  EditProfileMutation,
  CompanyBackgroundsQuery,
  TaskQuery,
  SubtaskQuery,
  UpdateOnboardingStepMutation,
  //GenerateJwtTokenForUser,
  //SendVideoCallInvitation,
  UpdateNotificationsMutation,
} from "@/gql";

export default {
  name: "CompanyView",
  api: {
    company: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
    user: {
      query: UserQuery,
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    userPermissions: {
      query: UserPermissionsQuery,
      cacheKey: "UserPermissionsQuery",
      defaultValue: null,
    },
    workspaces2: {
      query: Workspaces2Query,
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    folder: {
      query: FoldersQuery,
      cacheKey: "FoldersQuery",
      defaultValue: null,
    },
    notifications: {
      query: NotificationsQuery,
      cacheKey: "NotificationsQuery",
      defaultValue: null,
    },
    reactions: {
      query: ReactionsQuery,
      cacheKey: "ReactionsQuery",
      defaultValue: null,
    },
    statuses: {
      query: StatusesQuery,
      cacheKey: "StatusesQuery",
      defaultValue: null,
    },
    taskLabels: {
      query: TaskLabelsQuery,
      cacheKey: "TaskLabelsQuery",
      defaultValue: null,
    },
    roles: {
      query: RolesQuery,
      cacheKey: "RolesQuery",
      defaultValue: null,
    },
    rolePermissions: {
      query: RolePermissionsQuery,
      cacheKey: "RolePermissionsQuery",
      defaultValue: null,
    },
    importDataHistories: {
      query: ImportDataQuery,
      defaultValue: null,
      cacheKey() {
        return `ImportDataQuery`;
      },
    },
    pinPosts: {
      defaultValue: [],
      query: PinMessagesQuery,
      variables() {
        return {
          workspace_id: parseInt(this.currentWorkspaceId),
        };
      },
      cacheKey() {
        return `PinMessagesQuery:${this.currentWorkspaceId}`;
      },
    },
    bookmarks: {
      defaultValue: [],
      query: BookmarksQuery,
      variables() {
        return {
          workspace_id: parseInt(this.currentWorkspaceId),
        };
      },
      cacheKey() {
        return `BookmarksQuery:${this.currentWorkspaceId}`;
      },
    },
    bookmarkFolders: {
      defaultValue: [],
      query: BookmarkFoldersQuery,
      variables() {
        return {
          workspace_id: parseInt(this.currentWorkspaceId),
        };
      },
      cacheKey() {
        return `BookmarkFoldersQuery:${this.currentWorkspaceId}`;
      },
    },
    companyBackgrounds: {
      query: CompanyBackgroundsQuery,
      cacheKey: "CompanyBackgroundsQuery",
      defaultValue: [],
    },
    workspaceMembers: {
      defaultValue: [],
      cacheKey() {
        return `WorkspaceMembersQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  mixins: [
    UserScope,
    ManageDialogMixin,
    SignInToAnotherCompanyMixin,
    BoardsMethodMixin,
    MediaMethodMixin,
  ],
  components: {
    FooterMenuDialog,
    VideoDialog,
    MessagingToolbar,
    BoardToolbar,
    MediaToolbar,
    NavigationSidebar,
    UpdateNotification,
    InfoSidebar,
    NotificationsSidebar,
    HelpCenterSidebar,
    MyWorkToolbar,
    SavedItemsToolbar,
    PlanexpiredDialog,
    LearnViewToolbar,
    CheckStatusBanner,
    SigninDialog,
    TaskDialog,
    SubTaskDialog,
    TimeLogDialog,
    WelcomeDialog,
    Avatar,
  },
  data() {
    return {
      menu: false,
      showTooltip: true,
      selectedStatus: null,
      displayPlayer: false,
      showVideoModel: false,
      drawerLeft: "full",
      messageSearch: {
        searchText: null,
      },
      boardFilterObj: { ...boardFilterInitialState },
      boardSearchOptions: { ...boardSearchOptionsInitialState },
      mediaSearch: {
        searchText: "",
      },
      myWorkDateFilter: "All time",
      myworkSearch: {
        taskSearchText: null,
        memberSearchText: null,
      },
      showViewTabs: false,
      planeDescription: null,
      isShowImportStatusBanner: {
        flag: false,
        importCompleted: false,
      },
      footerMenuDialog: {
        show: false,
        selectedMenuItem: null,
      },
      taskDialog: false,
      task: null,
      subTaskDialog: false,
      currentTask: null,
      currentSubTask: null,
      timelogs: {
        flag: false,
        data: [],
        task: null,
        is_add_time: false,
      },
      videoCallData: {
        isDialogShow: false,
        roomName: null,
        jwtToken: null,
        window: null,
      },
      taskWorkspace: null,
      taskWorkspaceMember: null,
    };
  },
  beforeCreate() {
    this.$eventBus.$off("openTaskDialog");
    this.$eventBus.$off("openTimelogs");
  },
  created() {
    this.$eventBus.$on("openTaskDialog", this.openTaskDialog);
    this.$eventBus.$on("openTimelogs", this.openTimelogs);
    if (this.currentWorkspace) {
      const baordView = this.$store.getters.currentView(
        this.currentWorkspace.boards[0].id
      );
      const mediaView = this.$store.getters.currentMediaView(
        this.currentWorkspaceId
      );
      if (!baordView) {
        this.$store.dispatch("setTaskView", {
          boardId: this.currentWorkspace.boards[0].id,
          view: "board",
        });
      }
      if (!mediaView) {
        this.$store.dispatch("setMediaView", {
          workspaceId: this.currentWorkspaceId,
          view: "ListView",
        });
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.myworkSearch.taskSearchText = null;
    this.myworkSearch.memberSearchText = null;
    this.boardSearchOptions = { ...boardSearchOptionsInitialState };

    this.boardFilterObj = { ...boardFilterInitialState };
    next();
  },
  methods: {
    async closeNotificaiton() {
      if (this.drawerRight == "notifications") {
        this.toggleDrawerRight("notifications");
        if (!this.$store.getters.drawerState) {
          const query = this.$api.getQuery("NotificationsQuery");
          let notification_ids;
          if (query.data) {
            notification_ids = query.data.notifications
              .filter((n) => !n.read)
              .map((n) => {
                n.read = true;
                return n.id;
              });
            if (notification_ids.length) {
              const variables = {
                notification_ids: notification_ids,
                read: true,
              };
              await this.$api.mutate({
                mutation: UpdateNotificationsMutation,
                variables,
              });
            }
          }
        }
      }
    },
    closeVideoCallDialog(event) {
      if (!meetWindow || typeof event.data.isCloseDialog == "undefined") {
        return false;
      }
      event.preventDefault();
      meetWindow.close();
      return true;
    },
    async updateOnboardingWalkthrough() {
      let onboardingStep = 4;
      if (this.drawerLeft !== "mini" && this.$route.name === "BoardView") {
        onboardingStep = 1;
      }
      let variables = {
        onboarding_step: onboardingStep,
      };
      this.user.onboarding_step = onboardingStep;
      await this.$api.mutate({
        mutation: UpdateOnboardingStepMutation,
        variables,
      });
    },
    closeTimeLogDialog() {
      this.timelogs.flag = false;
      this.timelogs.task = null;
      this.timelogs.is_add_time = false;
      this.timelogs.is_from_workspace = false;
      this.timelogs.data = [];
    },
    openTimelogs(
      flag,
      timelogs,
      isAddTime = false,
      task = null,
      fromWorkspace = false
    ) {
      this.timelogs.data = timelogs;
      this.timelogs.flag = flag;
      this.timelogs.task = task;
      this.timelogs.is_add_time = isAddTime;
      this.timelogs.is_from_workspace = fromWorkspace;
    },
    openTaskDialog(obj) {
      if (obj && obj.task) {
        let taskData = obj.task;
        if (taskData.board_id) {
          const board = this.$api.getEntity("board", taskData.board_id);
          this.taskWorkspace = this.$api.getEntity(
            "workspace",
            board.workspace_id
          );
        } else {
          const card = this.$api.getEntity("card", taskData.card_id);
          const board = this.$api.getEntity("board", card.board_id);
          this.taskWorkspace = this.$api.getEntity(
            "workspace",
            board.workspace_id
          );
        }
        this.taskWorkspaceMember = this.taskWorkspace.users;
        this.editTask(obj);
        this.taskDialog = true;
      }
    },
    async openTask(taskId) {
      this.taskWorkspace = this.currentWorkspace;
      this.taskWorkspaceMember = this.workspaceMembers;
      const task = this.$api.getEntity("task", taskId);
      let taskData = null;
      if (task) {
        taskData = task;
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });
        taskData = taskQueryData.data.task;
      }

      if (taskData) {
        let isOpen = null;
        let task = taskData;
        let taskDetails = {
          task,
          isOpen,
        };
        this.editTask(taskDetails);
        this.taskDialog = true;
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    async backToTask(taskId) {
      await this.openTask(taskId);
      this.closeSubTaskDialog();
    },
    closeSubTaskDialog() {
      if (!isEmpty(this.$route.query)) {
        this.$router.replace({ query: null });
      }
      this.subTaskDialog = false;
      this.currentSubTask = null;
    },
    async openSubtaskDialog(
      taskId,
      subtaskId,
      taskData = null,
      subtask = null
    ) {
      if (taskData) {
        this.currentTask = taskData;
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });

        const taskEntity = taskQueryData.data.task;
        if (taskEntity) {
          this.currentTask = taskEntity;
        }
      }

      let subtaskEntity = null;
      if (subtask) {
        subtaskEntity = subtask;
      } else {
        let subtaskQueryData = await this.$api.query({
          query: SubtaskQuery,
          variables: {
            id: subtaskId,
          },
        });

        subtaskEntity = subtaskQueryData.data.subtask;
      }

      if (subtaskEntity) {
        this.closeTaskDialog();
        this.subTaskDialog = true;
        this.currentSubTask = subtaskEntity;
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    async closeTaskDialog() {
      // if (this.isNewTask) {
      //   const variables = {
      //     id: this.task.id,
      //   };
      //   const privateTasksQuery = this.$api.getQuery(
      //     `PrivateTasksQuery:${this.board.id}`
      //   );
      //   privateTasksQuery.data.privateTasks =
      //     privateTasksQuery.data.privateTasks.filter(
      //       (t) => t.id !== this.task.id
      //     );
      //   await this.$api.mutate({
      //     mutation: DeleteTaskMutation,
      //     variables,
      //   });
      //   const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);
      //   query.data.board.tasks = query.data.board.tasks.filter(
      //     (task) => task.id !== this.task.id
      //   );
      // }
      this.resetTaskDialog();
    },
    async changeWorkspaceSetting() {
      let variables = {
        first: this.user.first,
        last: this.user.last,
        username: this.user.username,
        lang: this.user.lang,
        web_notification: !this.user.web_notification,
      };
      await this.$api.mutate({
        mutation: EditProfileMutation,
        variables,
      });
    },
    async chooseStatus(status) {
      this.selectedStatus = status;
      let variables = {
        status: this.selectedStatus ? this.selectedStatus.id : null,
      };

      const response = await this.$api.mutate({
        mutation: UpdateUserStatusMutation,
        variables,
      });
      if (response.data && response.data.updateUserStatus) {
        const userEntity = this.$api.getEntity(
          "user",
          response.data.updateUserStatus.id
        );
        userEntity.status_id = status;
        userEntity.userStatus = response.data.updateUserStatus.userStatus;
      }
    },
    openLearnMore() {
      this.user.onboarding_step = 0;
    },
    routeTo(name) {
      if (this.$route.name !== name) {
        this.$router.push({ name });
        if (name === "LearnView") {
          this.$mixpanelEvent("learn", {});
        }
      }
    },
    async switchCompany(companyKey) {
      this.$store.dispatch("setLastVisit", this.$route.path);

      this.$router.push({
        name: "BoardView",
        params: {
          company: companyKey,
          workspace: 1,
        },
      });

      setTimeout(() => {
        location.reload();
      }, 500);
    },
    navigateTo(item) {
      this.footerMenuDialog.selectedMenuItem = item;
    },
    closeFooterMenuDialog() {
      this.footerMenuDialog.show = false;
    },
    signOutSpecificCompany(args) {
      this.folders &&
        this.folders.forEach((element) => {
          this.$store.dispatch("setFolderState", {
            folderId: element.id,
            flag: false,
          });
        });
      //this.$store.dispatch("removeFolderState", {});
      this.$store.dispatch("signoutFromCompany", args);
      this.unsubscribeAllChannel();
      this.$router.push({
        name: "SigninView",
      });
      resetState();
    },
    handleFooterMenuAction(item) {
      this.footerMenuDialog.selectedMenuItem = item;
      this.footerMenuDialog.show = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/579275845",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
      this.$mixpanelEvent("sidebar-help", {});
    },
    openCurrentUrl() {
      window.open(
        process.env.VUE_APP_APPBASE_URL + this.$route.fullPath,
        "heycollab",
        "width=900,height=600,scrollbars=yes,top=100,left=400,resizable = yes"
      );
    },
    async toggleSidebar() {
      await this.toggleDrawerLeft();
      //   const ps = this.$refs.workspaceSidebarList;
      for (const property in this.menuItems) {
        this.menuItems[property].model =
          this.drawerLeft === "mini" ? false : true;
      }
      //   if (ps) ps.scrollTop = 0;
    },
    async copyVideoCallLink(title, userId) {
      let videoCallLink = `https://${process.env.VUE_APP_MEET_URL}/${title
        .replace(/ /g, "-")
        .toLowerCase()}/${userId}`;

      this.$copyTextToClipboard(videoCallLink);
    },
    async inviteVideoCallLink(title, userId) {
      let videoCallLink = `https://${process.env.VUE_APP_MEET_URL}/${title
        .replace(/ /g, "-")
        .toLowerCase()}/${userId}`;

      let siteLink = `${process.env.VUE_APP_APPBASE_URL}/?room=${title
        .replace(/ /g, "-")
        .toLowerCase()}`;
      let inviteMettingTex = `${this.fullName} is inviting you to a meeting.\n\nJoin the meeting:\n${videoCallLink}\n\nLooking for a different dial-in number? \n See meeting dial-in numbers: ${siteLink}\n\n\nIf also dialing-in through a room phone, join without connecting to audio: ${videoCallLink}#config.startSilent=true`;

      this.$copyTextToClipboard(inviteMettingTex);
    },
    async openCompanyVideoDailog(title, userId, isPeople) {
      let videoUrl;
      if (isPeople) {
        videoUrl = `https://${process.env.VUE_APP_MEET_URL}/${title
          .replace(/ /g, "-")
          .toLowerCase()}`;
      } else {
        videoUrl = `https://${process.env.VUE_APP_MEET_URL}/${title
          .replace(/ /g, "-")
          .toLowerCase()}/${this.user.id}`;
      }

      if (userId && userId == this.user.id) {
        let generateToken = await this.$api.mutate({
          mutation: GenerateJwtTokenForUser,
        });

        if (generateToken.data) {
          videoUrl += "?jwt=" + generateToken.data.generateJwtTokenForUser;
        }
      }

      meetWindow = window.open(
        videoUrl,
        "heycollabVideo",
        "width=600,height=600,scrollbars=yes,top=100,left=400,resizable = yes"
      );
      window.addEventListener("message", this.closeVideoCallDialog, false);
    },
    openImportMenuDialog() {
      this.footerMenuDialog.show = true;
      this.footerMenuDialog.selectedMenuItem = "importData";
    },
    closePlanExpiredDialog() {
      this.$store.dispatch("videoPlayerData", {
        isDialogShow: false,
        videoUrl: "",
      });
    },
    toggleDrawerLeft() {
      this.drawerLeft = this.drawerLeft === "full" ? "mini" : "full";

      this.$emit("minimiseNaviagationSidebar", this.drawerLeft);
    },
    toggleDrawerRight(value) {
      this.$store.dispatch(
        "setDrawerState",
        this.drawerRight === value ? null : value
      );
    },
    unsubscribeAllChannel() {
      pusher.allChannels().forEach((channel) => {
        channel.unbind_all();
        pusher.unsubscribe(channel.name);
      });
    },
    changeView(view) {
      // if (
      //   this.currentCompany.status &&
      //   this.currentCompany.status.subscriptionOver &&
      //   view !== "board"
      // ) {
      //   this.showViewTabs = true;
      //   if (view === "list") {
      //     this.planeDescription =
      //       "View your tasks in list view, prioritize, organize and assign work among your team.";
      //   } else if (view === "calendar") {
      //     this.planeDescription =
      //       "View your tasks in a calendar-week or month view and plan work among your team members.";
      //   } else if (view === "timeline") {
      //     this.planeDescription =
      //       "View your tasks in a Timeline view and start seeing the whole picture, plan organize and move work forword.";
      //   } else {
      //     this.planeDescription = null;
      //   }
      // } else
      // {
      let eligible = this.$checkEligiblity(`${view}_view`);
      if (eligible) {
        this.$store.dispatch("setTaskView", {
          boardId: this.currentWorkspace.boards[0].id,
          view: view,
        });
        this.$mixpanelEvent(`${view}-view`, {});
      }
      // }
    },
    changeMediaView(view) {
      this.$store.dispatch("setMediaView", {
        workspaceId: this.currentWorkspaceId,
        view: view,
      });
    },
    setMyWorkDateFilter(filter) {
      this.myWorkDateFilter = filter;
    },
  },
  computed: {
    checkBanner() {
      if (
        this.currentCompany &&
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver &&
        this.currentCompany.status.trialOver &&
        this.isAdmin
      ) {
        return true;
      } else if (
        this.currentCompany.status.trialOver &&
        this.company.subscription.plan_id === 1 &&
        this.isAdmin
      ) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
      videoPlayerData: "videoPlayerData",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces2 &&
        this.workspaces2.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        return this.user.id === this.currentWorkspace.owner.id;
      }
      return false;
    },
    isVisiblePage() {
      if (
        (this.$route.name === "SavedItemsView" ||
          this.$route.name === "MyWorkView" ||
          this.$route.name === "LearnView") &&
        this.drawerRight !== "notifications"
      ) {
        return false;
      }
      return true;
    },
    drawerRight() {
      return this.$store.getters.drawerState;
    },
    taskView() {
      return this.currentWorkspace &&
        this.$store.getters.currentView(this.currentWorkspace.boards[0].id)
        ? this.$store.getters.currentView(this.currentWorkspace.boards[0].id)
            .view
        : "board";
    },
    mediaView() {
      return this.currentWorkspace &&
        this.$store.getters.currentMediaView(this.currentWorkspaceId)
        ? this.$store.getters.currentMediaView(this.currentWorkspaceId).view
        : "ListView";
    },
    nameInitials() {
      if (this.user) {
        return (
          this.user.first.charAt(0).toUpperCase() +
          "" +
          this.user.last.charAt(0).toUpperCase()
        );
      } else {
        return null;
      }
    },
    userProfilePhoto() {
      if (this.user && this.user.pic) {
        return this.user.pic;
      } else {
        return null;
      }
    },
    fullName() {
      if (this.user) return this.user.first + " " + this.user.last;
      return "";
    },
  },
};
</script>
<style lang="scss">
.q-drawer {
  top: 80px !important;
}
.sidebar-menu {
  .q-list {
    .q-item {
      padding: 6px 16px !important;
    }
  }
}
</style>
>
